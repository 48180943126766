import '../scss/main.scss';

// required polyfills for Webpack's dynamic imports
import 'core-js/modules/es.promise';
import 'core-js/modules/es.array.iterator';

import navigation from './modules/navigation'
import glider from './modules/glider'
import signUpForm from './vue/signup'
import signUpPlayer from './vue/signupPlayer'
import contact from './vue/contact'
import fields from './vue/fields'
import standings from './vue/standings'
import schedules from './vue/schedules'
import stats from './vue/stats'

document.addEventListener("DOMContentLoaded", () => {
  glider()
  signUpForm()
  signUpPlayer()
  contact()
  fields()
  schedules()
  standings()
  stats()
});

navigation()
