import { render, staticRenderFns } from "./StatsComponent.vue?vue&type=template&id=18e053a1&"
import script from "./StatsComponent.vue?vue&type=script&lang=js&"
export * from "./StatsComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('18e053a1')) {
      api.createRecord('18e053a1', component.options)
    } else {
      api.reload('18e053a1', component.options)
    }
    module.hot.accept("./StatsComponent.vue?vue&type=template&id=18e053a1&", function () {
      api.rerender('18e053a1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/vue/components/StatsComponent.vue"
export default component.exports