<template>
<section class="base block">
  <div class="field-block mb-6" v-for="(field, index) in fields">
    <span class="text-2xl block">{{field.name}}</span>
    <span class="block">{{field.address}}</span>
    <span class="block">{{field.city}}, <span class="uppercase">{{field.state}}</span> {{field.zip}}</span>
    <span class="directions mt-2 block">
      <a :href="field.urlName" class="text-bold text-blue-500 underline" target="_blank">Directions</a>
    </span>
  </div>
</section>
</template>

<script>

import axios from '../config/axios'
export default {
name: 'FieldsComponent',
// *----------------------- P r o p s ----------------------------------------------------------
props: {},
// *----------------------- D a t a -----------------------------------------------------------
components:{
},
data() {
  return {
    fields:null
  }
},
mounted(){
  // console.log(this.$route.params)
  axios.get('/fields')
    .then(response => {
      let fields = response.data
      fields = fields.map(field => {
        var temp = Object.assign({}, field);
        temp.urlName = encodeURI(`https://www.google.com/maps/dir/?api=1&destination=${field.name} ${field.address} ${field.city} ${field.state} ${field.zip}`);
        return temp;
      });
      this.fields = fields;
    })

},
// *----------------------- C o m p u t e d ---------------------------------------------------
computed: {},
// *----------------------- L i f e   c i r c l e ---------------------------------------------
created() {
    // this.$store.dispatch('base/getInit')
},
// *----------------------- M e t h o d s -----------------------------------------------------
methods: {},
// *----------------------- W a t c h ---------------------------------------------------------
watch: {}
}
</script>
