<template>
<section class="base block game-schedule">
  <div class="filter-block pb-6 mb-6 border-b border-blue-200" v-if="seasons">
    <div class="grid grid-cols-2 gap-4">
      <div class="col-span-2 lg:col-span-1">
        <div class="genericSelect">
          <select name="" id="" v-model="selectedSeason" @change="selectSeason($event)">
            <option :value="null">League</option>
            <option :value="season.id" v-for="(season, index) in seasons">{{season.generic_name}}</option>
          </select>
        </div>
      </div>
      <div class="col-span-2 lg:col-span-1">
        <div class="genericSelect">
          <select name="" id="" :disabled="!selectedSeason" @change="selectTeam($event)" v-model="selectedTeam">
            <option :value="null">Team</option>
            <option :value="team.id" v-for="(team, index) in teams">{{team.name}}</option>
          </select>
        </div>
      </div>
    </div>

  </div>

  <div class="games" v-if="filteredGames">
    <div class="game-block mb-12" v-for="(gameBlock, index) in filteredGames">
      <div class="date-header"><h4 class="mb-4 text-xl">{{index | formatDay}}</h4></div>
      <div class="game-container text-lg py-2 px-6 border border-blue-200">
        <div class="game w-full my-3" :class="{ isFinal: game.isFinal }" v-for="(game, index) in gameBlock">
          <span class="game-row w-full" v-if="game.home_team == 1">
            <span class="team-container inline-block sm:block sm:w-full sm:block" @click="goToGame(game.isFinal, game.id)">
              <span class="team font-bold">
                {{game.team_2.name}} <span class="score" v-if="game.isFinal">{{game.team_2_score}}</span>
              </span>
              <span class="at mx-2">@</span>
              <span class="home team font-bold">
                {{game.team_1.name}} <span class="score" v-if="game.isFinal">{{game.team_1_score}}</span>
              </span>
              <span>&#187;</span>
            </span>
          </span>
          <span class="game-row w-full" v-else-if="game.home_team == 2">
            <span class="team-container sm:w-full sm:block" @click="goToGame(game.isFinal, game.id)">
              <span class="team font-bold">
                {{game.team_1.name}} <span class="score" v-if="game.isFinal">{{game.team_1_score}}</span>
              </span>
              <span class="at mx-2">@</span>
              <span class="home team font-bold">
                {{game.team_2.name}} <span class="score" v-if="game.isFinal">{{game.team_2_score}}</span>
              </span>
              <span>&#187;</span>
            </span>
          </span>
          <span class="game-row w-full" v-else>
            <span class="team-container" @click="goToGame(game.isFinal, game.id)">
              <span class="team font-bold">
                {{game.team_1.name}} <span class="score" v-if="game.isFinal">{{game.team_1_score}}</span>
              </span>
              <span class="at mx-2">vs</span>
              <span class="home team font-bold">
                {{game.team_2.name}} <span class="score" v-if="game.isFinal">{{game.team_2_score}}</span>
              </span>
              <span>&#187;</span>
            </span>
          </span>
          <span class="time px-6 sm:pl-0 sm:pr-4 sm:text-sm">
            {{game.date_time | formatGame}}
          </span>
          <span class="info text-blue-500 col-span-2 sm:text-sm"><a href="/fields" class="text-blue-500">{{game.field.name}}</a></span>
        </div>
      </div>
    </div>
  </div>
  <section v-if="!filteredGames || !seasons" class="py-12">
    <div class="loading-holder">
      <LoadingSvg fill="#2C2D70"></LoadingSvg>
    </div>
  </section>
</section>

</template>

<script>

import axios from '../config/axios'
import { map, tail, times, uniq } from 'lodash';
import moment from 'moment';
import LoadingSvg from './LoadingSvg'

export default {
name: 'SchedulesComponent',
// *----------------------- P r o p s ----------------------------------------------------------
props: {},
// *----------------------- D a t a -----------------------------------------------------------
components:{
  LoadingSvg
},
computed: {
  leagues() {

    // return this.games
  }
},
data() {
  return {
    games:null,
    filteredGames:null,
    leagues:null,
    teams:null,
    seasons: null,
    selectedTeam: null,
    selectedSeason: null
  }
},
mounted(){
  axios.get('/leagues')
    .then(response => {
      this.seasons = response.data
      const {season, team} = this.$route.params;
      if(season){
        this.selectedSeason = season
          this.getTeams(()=>{
            if(team){
              this.selectedTeam = team;
            }
            this.getSchedule(false)
          })
      }else{
        this.getSchedule(false)
      }

    })

},
// *----------------------- C o m p u t e d ---------------------------------------------------
computed: {

},
// *----------------------- L i f e   c i r c l e ---------------------------------------------
created() {
    // this.$store.dispatch('base/getInit')
},
// *----------------------- M e t h o d s -----------------------------------------------------
methods: {
  getSchedule(withPush, cb){
    let scheduleUrl =  `/games`;
    if(this.selectedSeason != null){
      scheduleUrl = scheduleUrl+`/${this.selectedSeason}`;
      if(this.selectedTeam != null){
        scheduleUrl = scheduleUrl+`/${this.selectedTeam}`;
        this.$router.push({ path: `/${this.selectedSeason}/${this.selectedTeam}`})
      }
    }
    if(withPush){
      this.$router.push({ path: `/${this.selectedSeason}/${this.selectedTeam}`})
    }
    axios.get(scheduleUrl)
      .then(response => {
        this.games = response.data
        this.filteredGames = response.data
        if(cb){
          cb();
        }
      })
  },
  goToGame(isFinal, id){
    if(!isFinal){
      return
    }
    this.$router.push({ path: `/game/${id}`})
  },
  getTeams(cb){
    axios.get(`/leagues/teams/${this.selectedSeason}`)
      .then(response => {
        this.teams = response.data
        if(cb){
          cb();
        }
      })
  },
  selectTeam(event){
    this.filteredGames = null;
    this.getSchedule(true)
  },
  selectSeason(event){
    this.filteredGames = null;
    this.selectedTeam = null;
    this.getSchedule(true, ()=>{
      this.getTeams()
    })
  }
},
// *----------------------- W a t c h ---------------------------------------------------------
watch: {}
}
</script>
