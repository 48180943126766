import Glide from '@glidejs/glide'
import anime from 'animejs/lib/anime.es.js';

export default function() {
  if(!document.querySelectorAll('.glide').length){
    return
  }
  let isActive = false
  let gliderIndex = 0
  let currentEl, previousEl
  let animation = initSlides()

  let glider = new Glide('.glide', {
    type:'carousel',
    // autoplay:6000,
  })
  glider.on('mount.after', function() {
    currentEl = getElement(gliderIndex)
    window.setTimeout(function(){
        animation[0].play()
    },500)

  })
  glider.on('move.after', function() {
    if(glider.index != gliderIndex){
      animation[gliderIndex].seek(0)
      gliderIndex = glider.index
      animation[gliderIndex].play()
    }

    if(!isActive){

    }
  })

  glider.mount()
}

function initSlides(){
  let slides = getElements();
  let slideAnimations = [];
  slides.forEach(element => {
    let childEl = element.querySelectorAll(`.slide-content-container > .slide-animate`)
    let slideAnimation = animateSlide(childEl)
    slideAnimations.push(slideAnimation)
  })
  return slideAnimations
}

function getElements(){
  return document.querySelectorAll(`[data-slide]`);
}

function getElement(i){
  return document.querySelectorAll(`[data-slide="${i}"] > .slide-content-container > .slide-animate`)
}

function animateSlide(el){
  return anime({
    targets: el,
    translateX: ['-100%','0%'],
    easing: 'spring(1, 80, 10, 0)',
    autoplay: false,
    duration: 2000,
    delay: anime.stagger(100), // increase delay by 100ms for each elements.
  })
}
