import Vue from 'vue';
import FieldsComponent from './components/FieldsComponent';

export default function() {
    if(!document.querySelectorAll('#fields').length){
      return
    }
    new Vue({
        el: '#fields',
        delimiters: [
            '${', '}'
        ],
        components: {
        },
        methods: {},
        mounted() {
        },
        render: h => h(FieldsComponent),
        computed: {}
    });
}
