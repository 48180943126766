<template>
  <section class="layout">
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: 'Layout',
  // *----------------------- P r o p s ----------------------------------------------------------
  props: {},
  // *----------------------- D a t a -----------------------------------------------------------
  components:{

  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Schedules',
    // all titles will be injected into this template
    titleTemplate: '%s | Keystone Softball'
  },
  data() {
    return {}
  },
  // *----------------------- C o m p u t e d ---------------------------------------------------
  computed: {},
  // *----------------------- L i f e   c i r c l e ---------------------------------------------
  created() {

  },
  // *----------------------- M e t h o d s -----------------------------------------------------
  methods: {},
  // *----------------------- W a t c h ---------------------------------------------------------
  watch: {}
}
</script>
