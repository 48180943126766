var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "base block text pb-12" },
    [
      _c(
        "MountingPortal",
        {
          attrs: { mountTo: "#hero-portal-target", name: "source", append: "" }
        },
        [
          _vm.gameData
            ? _c("h2", [_c("span", [_vm._v(_vm._s(_vm.gameTitle))])])
            : _vm._e(),
          _vm._v(" "),
          _vm.gameData
            ? _c("h4", [
                _c("span", { staticClass: "date" }, [
                  _vm._v(_vm._s(_vm._f("formatDay")(_vm.gameData.date_time)))
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.gameData
        ? _c("section", { staticClass: "game-details overflow-x-scroll" }, [
            _vm.gameData.home_team == 1
              ? _c(
                  "div",
                  [
                    _c("BoxScoreBlock", {
                      staticClass: "mt-12",
                      attrs: {
                        boxData: _vm.gameData.stats.team_2_stats,
                        score: _vm.gameData.team_2_score,
                        totals: _vm.gameTotals.team_2,
                        team: _vm.gameData.team_2.name
                      }
                    }),
                    _vm._v(" "),
                    _c("BoxScoreBlock", {
                      attrs: {
                        boxData: _vm.gameData.stats.team_1_stats,
                        score: _vm.gameData.team_1_score,
                        totals: _vm.gameTotals.team_1,
                        team: _vm.gameData.team_1.name
                      }
                    })
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c("BoxScoreBlock", {
                      attrs: {
                        boxData: _vm.gameData.stats.team_1_stats,
                        score: _vm.gameData.team_1_score,
                        totals: _vm.gameTotals.team_1,
                        team: _vm.gameData.team_1.name
                      }
                    }),
                    _vm._v(" "),
                    _c("BoxScoreBlock", {
                      staticClass: "mt-12",
                      attrs: {
                        boxData: _vm.gameData.stats.team_2_stats,
                        score: _vm.gameData.team_2_score,
                        totals: _vm.gameTotals.team_2,
                        team: _vm.gameData.team_2.name
                      }
                    })
                  ],
                  1
                )
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.gameData
        ? _c("section", { staticClass: "py-12" }, [
            _c(
              "div",
              { staticClass: "loading-holder" },
              [_c("LoadingSvg", { attrs: { fill: "#2C2D70" } })],
              1
            )
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }