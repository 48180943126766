<template>
<section class="base block">
  <span class="text-3xl sm:text-2xl block border-b border-gray-200 pb-2 mb-2">{{team}} {{score}}</span>
  <table class="table-auto w-full stat-table">
    <thead>
      <tr>
        <th class="px-4 py-2 text-left">Player</th>
        <th class="px-4 py-2">AB</th>
        <th class="px-4 py-2">R</th>
        <th class="px-4 py-2">H</th>
        <th class="px-4 py-2">1B</th>
        <th class="px-4 py-2">2B</th>
        <th class="px-4 py-2">3B</th>
        <th class="px-4 py-2">HR</th>
        <th class="px-4 py-2">RBI</th>
        <th class="px-4 py-2">BB</th>
        <th class="px-4 py-2">K</th>
        <th class="px-4 py-2">SACF</th>
        <th class="px-4 py-2">SACB</th>

      </tr>
    </thead>
    <tbody>
      <tr v-for="(statRow, index) in boxData" :class="{'bg-gray-200': index % 2 === 0}">
        <td class="px-4 py-2 text-left">{{statRow.player.first_name}} {{statRow.player.last_name}}</td>
        <td class="px-4 py-2">{{statRow.ab | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.r | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.b1+statRow.b2+statRow.b3+statRow.hr}}</td>
        <td class="px-4 py-2">{{statRow.b1 | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.b2 | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.b3 | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.hr | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.rbi | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.bb | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.k | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.sacf | checkStatValue}}</td>
        <td class="px-4 py-2">{{statRow.sacb | checkStatValue}}</td>
      </tr>
      <tr class="border-t border-blue-300 font-bold">
        <td class="px-4 py-2 text-left"></td>
        <td class="px-4 py-2">{{totals.ab | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.r | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.h}}</td>
        <td class="px-4 py-2">{{totals.b1 | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.b2 | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.b3 | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.hr | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.rbi | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.bb | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.k | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.sacf | checkStatValue}}</td>
        <td class="px-4 py-2">{{totals.sacb | checkStatValue}}</td>
      </tr>

    </tbody>
  </table>
</section>
</template>

<script>

export default {
name: 'BoxScoreBlock',
// *----------------------- P r o p s ----------------------------------------------------------
props: ['boxData', 'team', 'score', 'totals'],
// *----------------------- D a t a -----------------------------------------------------------
components:{

},
data() {
  return {}
},
mounted(){
},
// *----------------------- C o m p u t e d ---------------------------------------------------
computed: {},
// *----------------------- L i f e   c i r c l e ---------------------------------------------
created() {
    // this.$store.dispatch('base/getInit')
},
// *----------------------- M e t h o d s -----------------------------------------------------
methods: {},
// *----------------------- W a t c h ---------------------------------------------------------
watch: {}
}
</script>
