var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "layout" }, [
    _c("h3", [_vm._v("Learn More")]),
    _vm._v(" "),
    _c("p", [
      _vm._v(
        "Enter your email below to learn more about the league and contact the commissioner."
      )
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "relative",
        class: { loading: _vm.loading },
        attrs: { id: "app", novalidate: "true" }
      },
      [
        _c("p", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            attrs: {
              id: "email",
              type: "email",
              name: "email",
              placeholder: "Email Address"
            },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          }),
          _c("input", {
            attrs: { type: "button", value: "Submit" },
            on: { click: _vm.checkForm }
          })
        ]),
        _vm._v(" "),
        _vm.loading ? _c("loading", { attrs: { fill: "#000" } }) : _vm._e(),
        _vm._v(" "),
        _vm.errors.length
          ? _c("p", { staticClass: "mt-4" }, [
              _c("b", [_vm._v("Please correct the following error(s):")]),
              _vm._v(" "),
              _c(
                "ul",
                _vm._l(_vm.errors, function(error) {
                  return _c("li", [_vm._v(_vm._s(error))])
                }),
                0
              )
            ])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }