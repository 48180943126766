<template>
<section class="base block text-2xl">
  <div class="standings-block mb-10 border-b pb-6" v-for="(standing, index) in standings">
    <h3>{{standing.name}}</h3>
    <div class="division-container mt-6">
      <div v-for="(division, index) in standing.divisions" class="mb-6">

        <h5 class="text-lg mt-6 text-blue-500" v-if="standing.divisions.length > 1">{{division.level}} Level</h5>
        <table class="table-auto sm:text-sm text-lg">
          <tr>
            <th class="px-4 py-1"></th>
            <th class="px-4 py-1">Wins</th>
            <th class="px-4 py-1">Losses</th>
            <th class="px-4 py-1">Ties</th>
            <th class="px-4 py-1 whitespace-no-wrap">Win %</th>
            <th class="px-4 py-1">RS</th>
            <th class="px-4 py-1">RA</th>
          </tr>
          <tr v-for="(roster, index) in division.rosters">
            <td class="pr-4 py-1" style="width:200px;">{{roster.team}}</td>
            <td class="px-4 py-1 text-center">{{roster.record.wins}}</td>
            <td class="px-4 py-1 text-center">{{roster.record.losses}}</td>
            <td class="px-4 py-1 text-center">{{roster.record.ties}}</td>
            <td class="px-4 py-1 text-center">{{roster.record.winningPercentage.toFixed(3)}}</td>
            <td class="px-4 py-1 text-center">{{roster.record.rs}}</td>
            <td class="px-4 py-1 text-center">{{roster.record.ra}}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</section>
</template>

<script>

import axios from '../config/axios'
export default {
name: 'StandingsComponent',
// *----------------------- P r o p s ----------------------------------------------------------
props: {},
// *----------------------- D a t a -----------------------------------------------------------
components:{
},
data() {
  return {
    standings: null
  }
},
mounted(){
  // console.log(this.$route.params)
  axios.get('/standings')
    .then(response => {
      console.log(response.data)
      this.standings = response.data
    })

},
// *----------------------- C o m p u t e d ---------------------------------------------------
computed: {},
// *----------------------- L i f e   c i r c l e ---------------------------------------------
created() {
    // this.$store.dispatch('base/getInit')
},
// *----------------------- M e t h o d s -----------------------------------------------------
methods: {},
// *----------------------- W a t c h ---------------------------------------------------------
watch: {}
}
</script>
