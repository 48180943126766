import { render, staticRenderFns } from "./SignupForm.vue?vue&type=template&id=9efd6602&"
import script from "./SignupForm.vue?vue&type=script&lang=js&"
export * from "./SignupForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/circleci/project/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('9efd6602')) {
      api.createRecord('9efd6602', component.options)
    } else {
      api.reload('9efd6602', component.options)
    }
    module.hot.accept("./SignupForm.vue?vue&type=template&id=9efd6602&", function () {
      api.rerender('9efd6602', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/vue/components/SignupForm.vue"
export default component.exports