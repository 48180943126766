import Vue from 'vue';
import ContactForm from './components/ContactForm';

export default function() {
    if(!document.querySelectorAll('#contactForm').length){
      return
    }
    new Vue({
        el: '#contactForm',
        delimiters: [
            '${', '}'
        ],
        components: {
        },
        methods: {},
        mounted() {
        },
        render: h => h(ContactForm),
        computed: {}
    });
}
