<template>
<section class="base block text pb-12">
  <MountingPortal mountTo="#hero-portal-target" name="source" append>
    <h2 v-if="gameData">
      <span>{{gameTitle}}</span>
    </h2>
    <h4 v-if="gameData">
      <span class="date">{{gameData.date_time | formatDay}}</span>
    </h4>
  </MountingPortal>
  <section class="game-details overflow-x-scroll" v-if="gameData">
    <div v-if="gameData.home_team == 1">
      <BoxScoreBlock class="mt-12" :boxData="gameData.stats.team_2_stats" :score="gameData.team_2_score" :totals="gameTotals.team_2" :team="gameData.team_2.name"></BoxScoreBlock>
      <BoxScoreBlock :boxData="gameData.stats.team_1_stats" :score="gameData.team_1_score" :totals="gameTotals.team_1" :team="gameData.team_1.name"></BoxScoreBlock>
    </div>
    <div v-else>
      <BoxScoreBlock :boxData="gameData.stats.team_1_stats" :score="gameData.team_1_score" :totals="gameTotals.team_1" :team="gameData.team_1.name"></BoxScoreBlock>
      <BoxScoreBlock class="mt-12" :boxData="gameData.stats.team_2_stats" :score="gameData.team_2_score" :totals="gameTotals.team_2" :team="gameData.team_2.name"></BoxScoreBlock>
    </div>
  </section>

  <section v-if="!gameData" class="py-12">
    <div class="loading-holder">
      <LoadingSvg fill="#2C2D70"></LoadingSvg>
    </div>
  </section>
</section>
</template>

<script>

import axios from '../config/axios'
import LoadingSvg from './LoadingSvg'
import BoxScoreBlock from './BoxScoreBlock'

export default {
name: 'SchedulesComponent',
// *----------------------- P r o p s ----------------------------------------------------------
props: {},
// *----------------------- D a t a -----------------------------------------------------------
components:{
  LoadingSvg,
  BoxScoreBlock
},
data() {
  return {
    gameData : null,
    gameTotals: null
  }
},
metaInfo () {
    return {
      title: this.gameTitle,
      meta:[
        {
        property: 'og:title',
        content: this.gameTitle,
        vmid: 'og:title'
        }
      ]

    }
  },
mounted(){
  axios.get(`/game/${this.$route.params.id}`)
    .then(response => {
      this.gameData = response.data
      this.gameTotals = this.calculateGameTotals()
    })

},
// *----------------------- C o m p u t e d ---------------------------------------------------
computed: {
  gameTitle: function () {
    if(this.gameData){
      if(this.gameData.home_team == 1){
        return `${this.gameData.team_2.name} ${this.gameData.team_2_score} @ ${this.gameData.team_1.name} ${this.gameData.team_1_score}`
      }else if(this.gameData.home_team == 2){
        return `${this.gameData.team_1.name} ${this.gameData.team_1_score} @ ${this.gameData.team_2.name} ${this.gameData.team_2_score}`
      }else{
        return `${this.gameData.team_1.name} ${this.gameData.team_1_score} vs ${this.gameData.team_2.name} ${this.gameData.team_2_score}`
      }
    }else{
      return ''
    }
    // `this` points to the vm instance
    return this.message.split('').reverse().join('')
  },
},
// *----------------------- L i f e   c i r c l e ---------------------------------------------
created() {
    // this.$store.dispatch('base/getInit')
},
// *----------------------- M e t h o d s -----------------------------------------------------
methods: {
  calculateGameTotals(){
    const computeTotals = (team) =>{
      let team_stats = {
        ab:null,
        h:null,
        b1:null,
        b2:null,
        b3:null,
        hr:null,
        bb:null,
        k:null,
        r:null,
        rbi:null,
        sacf:null,
        sacb:null
      }
      team.forEach((stat)=>{
        for (const property in stat) {
          if(team_stats.hasOwnProperty(property)){
            team_stats[property] += stat[property]
          }
        }
        team_stats.h += (stat.b1 + stat.b2 + stat.b3 + stat.hr)
      })
      return team_stats
    }
    const team_1_totals = computeTotals(this.gameData.stats.team_1_stats)
    const team_2_totals = computeTotals(this.gameData.stats.team_2_stats)

    return {
      team_1:team_1_totals,
      team_2:team_2_totals
    }
  }
},
// *----------------------- W a t c h ---------------------------------------------------------
watch: {}
}
</script>
