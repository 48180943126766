import Vue from 'vue';
import SignupForm from './components/SignupForm';

export default function() {
    if(!document.querySelectorAll('#signUpForm').length){
      return
    }
    new Vue({
        el: '#signUpForm',
        delimiters: [
            '${', '}'
        ],
        components: {
        },
        methods: {},
        mounted() {
        },
        render: h => h(SignupForm),
        computed: {}
    });
}
