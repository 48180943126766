<template>
  <section class="component-name  contact-component">
    <p class="header">Sign-up below:</p>
    <form action=""
    novalidate="true"
    class="relative"
    v-bind:class="{ loading: loading }">
      <div class="form-row error-message" v-if="$v.$error" :class="{ 'form-row--error': $v.$error }">
        There's an error with your submission. Please check that values are correct.
      </div>
      <div class="form-row select">
        <label for="registrationType">Registration Type</label>
        <p class="note">Please select if you're signing up as a team or a free-agent looking to join a team</p>
        <select class="" name="registrationType" v-model="registrationType" :class="{ 'form-row--error': $v.registrationType.$error }">
          <option value="" disabled selected hidden>Select Type</option>
          <option value="Free-Agent">Free-Agent</option>
          <option value="Team">Team</option>
        </select>
      </div>
      <div class="form-row">
        <label for="leagues">League Interest</label>
        <p class="note">Please note what league/leagues you're interested in joining</p>
        <input type="text" name="leagues" v-model="leagues">
      </div>
      <div class="form-row">
        <label for="fromName">Name</label>
        <input type="text" name="fromName" :class="{ 'form-row--error': $v.name.$error }" v-model="name">
      </div>
      <div class="form-row">
        <label for="name">Email</label>
        <input type="email" name="email" :class="{ 'form-row--error': $v.email.$error }" v-model="email">
      </div>
      <div class="form-row">
        <label for="name">Phone</label>
        <input type="phone" name="phone" :class="{ 'form-row--error': $v.phone.$error }" v-model="phone">
      </div>
      <div class="form-row">
        <label for="message">Message</label>
        <textarea name="mesasge" rows="6" :class="{ 'form-row--error': $v.message.$error }" v-model="message"></textarea>
      </div>
      <div class="form-row">
        <button class="button" @click="submitForm">Send!</button>
      </div>
      <loading v-if="loading" fill="#000"></loading>
    </form>
  </section>
</template>

<script>

import { validationMixin } from 'vuelidate'
import { required, minLength, between } from 'vuelidate/lib/validators'
import axios from 'axios'
import qs from 'qs'
import Noty from "noty";
import loading from './Loading'

export default {
  name: 'ContactForm',
  mixins: [validationMixin],
  components:{
    loading
  },
  // *----------------------- P r o p s ----------------------------------------------------------
  props: {},
  // *----------------------- D a t a -----------------------------------------------------------
  data() {
    return {
      name:'',
      registrationType:'',
      email:'',
      phone:'',
      message:'',
      leagues:'',
      errors: [],
      loading:false,
      submitStatus:null
    }
  },
  validations: {
    name: {
      required,
    },
    registrationType: {
      required,
    },
    email: {
      required
    },
    phone: {
      required
    },
    message: {
      required
    }
  },
  // *----------------------- C o m p u t e d ---------------------------------------------------
  computed: {},
  // *----------------------- L i f e   c i r c l e ---------------------------------------------
  created() {},
  // *----------------------- M e t h o d s -----------------------------------------------------
  methods: {
    submitForm(e){
      let that = this
      this.$v.$touch()
      console.log("THHHI", this.$v)
      if (this.$v.$invalid) {
        this.submitStatus = 'ERROR'
      } else {
        // do your submit logic here
        this.submitStatus = 'PENDING'
        setTimeout(() => {
          this.submitStatus = 'OK'
          let requestBody = {
            fromEmail:that.email,
            message: {
              registrationType:that.registrationType,
              leagues:that.leagues,
              phone: that.phone,
              body:that.message,

            },
            fromName: that.name,
            action:"contact-form/send",
            subject:`Signup message from ${that.name} via KeystoneSoftball.com`
          }
          const config = {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
              'Accept': 'application/json, text/javascript, */*; q=0.01',
              'x-requested-with': 'XMLHttpRequest'
            }
          }
          requestBody[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue;
          this.loading = true
          setTimeout(function(){
            axios.post('/', qs.stringify(requestBody), config)
            .then((result) => {
              that.$v.$reset()
              that.loading = false
              that.email = ""
              that.message = ""
              that.name = ""
              that.phone = ""
              new Noty({
                text: "Message sent!",
                type: 'success',
                theme: 'sunset',
                layout: 'bottomRight',
                timeout: '2000'
              }).show();
            })
            .catch((err) => {
              that.loading = false
              console.log("ERR", err)
              new Noty({
                text: "There's been an error sending your message",
                type: 'error',
                theme: 'sunset',
                layout: 'bottomRight',
                timeout: '2000'
              }).show();
            })
          }, 1000);
        }, 500)
      }
      // console.log(this.form)

      e.preventDefault();
    }
  },
  // *----------------------- W a t c h ---------------------------------------------------------
  watch: {}
}
</script>
