var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "base block text-2xl" },
    _vm._l(_vm.standings, function(standing, index) {
      return _c("div", { staticClass: "standings-block mb-10 border-b pb-6" }, [
        _c("h3", [_vm._v(_vm._s(standing.name))]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "division-container mt-6" },
          _vm._l(standing.divisions, function(division, index) {
            return _c("div", { staticClass: "mb-6" }, [
              standing.divisions.length > 1
                ? _c("h5", { staticClass: "text-lg mt-6 text-blue-500" }, [
                    _vm._v(_vm._s(division.level) + " Level")
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "table",
                { staticClass: "table-auto sm:text-sm text-lg" },
                [
                  _vm._m(0, true),
                  _vm._v(" "),
                  _vm._l(division.rosters, function(roster, index) {
                    return _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "pr-4 py-1",
                          staticStyle: { width: "200px" }
                        },
                        [_vm._v(_vm._s(roster.team))]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "px-4 py-1 text-center" }, [
                        _vm._v(_vm._s(roster.record.wins))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "px-4 py-1 text-center" }, [
                        _vm._v(_vm._s(roster.record.losses))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "px-4 py-1 text-center" }, [
                        _vm._v(_vm._s(roster.record.ties))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "px-4 py-1 text-center" }, [
                        _vm._v(
                          _vm._s(roster.record.winningPercentage.toFixed(3))
                        )
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "px-4 py-1 text-center" }, [
                        _vm._v(_vm._s(roster.record.rs))
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "px-4 py-1 text-center" }, [
                        _vm._v(_vm._s(roster.record.ra))
                      ])
                    ])
                  })
                ],
                2
              )
            ])
          }),
          0
        )
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", { staticClass: "px-4 py-1" }),
      _vm._v(" "),
      _c("th", { staticClass: "px-4 py-1" }, [_vm._v("Wins")]),
      _vm._v(" "),
      _c("th", { staticClass: "px-4 py-1" }, [_vm._v("Losses")]),
      _vm._v(" "),
      _c("th", { staticClass: "px-4 py-1" }, [_vm._v("Ties")]),
      _vm._v(" "),
      _c("th", { staticClass: "px-4 py-1 whitespace-no-wrap" }, [
        _vm._v("Win %")
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "px-4 py-1" }, [_vm._v("RS")]),
      _vm._v(" "),
      _c("th", { staticClass: "px-4 py-1" }, [_vm._v("RA")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }