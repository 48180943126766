import Vue from 'vue';
import SignUpPlayer from './components/SignUpPlayer';

export default function() {
    if(!document.querySelectorAll('#signupPlayerForm').length){
      return
    }
    new Vue({
        el: '#signupPlayerForm',
        delimiters: [
            '${', '}'
        ],
        components: {
        },
        methods: {},
        mounted() {

        },
        render: h => h(SignUpPlayer),
        computed: {}
    });
}
