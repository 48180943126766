var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "component-name" }, [
    _c("p", { staticClass: "header" }, [_vm._v("Contact us below:")]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "relative",
        class: { loading: _vm.loading },
        attrs: { action: "", novalidate: "true" }
      },
      [
        _vm.$v.$error
          ? _c(
              "div",
              {
                staticClass: "form-row error-message",
                class: { "form-row--error": _vm.$v.$error }
              },
              [
                _vm._v(
                  "\n      There's an error with your submission. Please check that values are correct.\n    "
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("label", { attrs: { for: "fromName" } }, [_vm._v("Name")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.name,
                expression: "name"
              }
            ],
            class: { "form-row--error": _vm.$v.name.$error },
            attrs: { type: "text", name: "fromName" },
            domProps: { value: _vm.name },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.name = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Email")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            class: { "form-row--error": _vm.$v.email.$error },
            attrs: { type: "email", name: "email" },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("label", { attrs: { for: "name" } }, [_vm._v("Phone")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone"
              }
            ],
            class: { "form-row--error": _vm.$v.phone.$error },
            attrs: { type: "phone", name: "phone" },
            domProps: { value: _vm.phone },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.phone = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c("label", { attrs: { for: "message" } }, [_vm._v("Message")]),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.message,
                expression: "message"
              }
            ],
            class: { "form-row--error": _vm.$v.message.$error },
            attrs: { name: "mesasge", rows: "6" },
            domProps: { value: _vm.message },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.message = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-row" }, [
          _c(
            "button",
            { staticClass: "button", on: { click: _vm.submitForm } },
            [_vm._v("Send!")]
          )
        ]),
        _vm._v(" "),
        _vm.loading ? _c("loading", { attrs: { fill: "#000" } }) : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }