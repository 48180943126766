/* Toggle between adding and removing the "responsive" class to topnav when the user clicks on the icon */

function menuToggle(x){
  console.log(x)
  if (x.className === "inactive") {
    x.className = "active"
  } else {
    x.className = "inactive"
  }
}

var toggleClassSub = function() {
    console.log(this)
    this.classList.toggle("expanded");
};

export default function() {
  let menu = document.getElementById("mainNavigation")
  let menuTarget = document.getElementById("navigationDropdown")
  console.log(menuTarget)
  console.log(menu)
  menuTarget.addEventListener('click', ()=> {
    menuToggle(menu)
  })

  var classname = document.getElementsByClassName("with-sub-nav");



  Array.from(classname).forEach(function(element) {
    element.addEventListener('click', toggleClassSub);
  });
}
