export default {
  table: {
    tableWrapper: '',
    tableHeaderClass: 'px-4 py-2',
    tableBodyClass: 'px-4 py-2',
    tableClass: 'min-w-full divide-y divide-gray-200 table-auto stat-table',
    loadingClass: 'loading',
    ascendingIcon: 'fas fa-chevron-up align-middle text-sm',
    descendingIcon: 'fas fa-chevron-down align-middle text-sm mt-1',
    ascendingClass: 'sorted-asc',
    descendingClass: 'sorted-desc',
    sortableIcon: 'grey sort icon',
    handleIcon: 'grey sidebar icon',
  },

  pagination: {
    wrapperClass: 'w-full flex justify-center lg:justify-end mt-8',
    activeClass: 'active large',
    disabledClass: 'disabled',
    pageClass: 'item block align-middle flex items-center py-1 px-3',
    linkClass: 'icon block align-middle flex items-center py-1 px-3',
    paginationClass: 'ui bottom attached segment grid',
    paginationInfoClass: 'left floated left aligned six wide column',
    dropdownClass: 'ui search dropdown',
    icons: {
      first: 'fas fa-angle-double-left',
      prev: 'fas fa-angle-left',
      next: 'fas fa-angle-right',
      last: 'fas fa-angle-double-right',
    }
  },

  paginationInfo: {
    infoClass: 'left floated left aligned six wide column',
  }
}
