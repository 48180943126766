<template>
  <section class="layout">
    <h3>Learn More</h3>
    <p>Enter your email below to learn more about the league and contact the commissioner.</p>
    <form
      id="app"
      novalidate="true"
      class="relative"
      v-bind:class="{ loading: loading }"
    >
      <p>
        <input
          id="email"
          v-model="email"
          type="email"
          name="email"
          placeholder="Email Address"
        ><input
          type="button"
          value="Submit"
          @click="checkForm"
        >
      </p>
      <loading v-if="loading" fill="#000"></loading>
      <p v-if="errors.length" class="mt-4">
        <b>Please correct the following error(s):</b>
        <ul>
          <li v-for="error in errors">{{ error }}</li>
        </ul>
      </p>
    </form>
  </section>
</template>

<script>

import axios from 'axios'
import qs from 'qs'
import Noty from "noty";
import loading from './Loading'
export default {
  name: 'SignupForm',
  // *----------------------- P r o p s ----------------------------------------------------------
  props: {},
  // *----------------------- D a t a -----------------------------------------------------------
  components:{
    loading
  },
  data() {
    return {
      errors: [],
      email: null,
      loading:false
    }
  },
  // *----------------------- C o m p u t e d ---------------------------------------------------
  computed: {},
  // *----------------------- L i f e   c i r c l e ---------------------------------------------
  created() {

  },
  // *----------------------- M e t h o d s -----------------------------------------------------
  methods: {
    checkForm: function (e) {
      this.errors = [];
      let that = this
      if (!this.email) {
        this.errors.push('Email required.');
      } else if (!this.validEmail(this.email)) {
        this.errors.push('Valid email required.');
      }

      if (!this.errors.length) {
        //VALID FORM
        let requestBody = {
          fromEmail:this.email,
          message: "This message is in response to a vistor requesting more info from the website",
          action:"contact-form/send",
          subject:"I'd like to know more about Keystone Softball via KeystoneSoftball.com"
        }
        const config = {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'x-requested-with': 'XMLHttpRequest'
          }
        }
        requestBody[window.Craft.csrfTokenName] = window.Craft.csrfTokenValue;
        this.loading = true
        setTimeout(function(){
          axios.post('/', qs.stringify(requestBody), config)
          .then((result) => {
            that.loading = false
            that.email = ""
            new Noty({
              text: "Message sent!",
              type: 'success',
              theme: 'sunset',
              layout: 'bottomRight',
              timeout: '2000'
            }).show();
          })
          .catch((err) => {
            that.loading = false
            new Noty({
              text: "There's been an error sending your message",
              type: 'error',
              theme: 'sunset',
              layout: 'bottomRight',
              timeout: '2000'
            }).show();
          })
        }, 1000);
      }

      e.preventDefault();
    },
    validEmail: function (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  },
  // *----------------------- W a t c h ---------------------------------------------------------
  watch: {}
}
</script>
