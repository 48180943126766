<template>
<section class="base block text-2xl">
  Player page
</section>
</template>

<script>
export default {
name: 'PlayerStatsComponent',
// *----------------------- P r o p s ----------------------------------------------------------
props: {},
// *----------------------- D a t a -----------------------------------------------------------
components:{
},
data() {
  return {}
},
mounted(){
  console.log(this.$route.params)
},
// *----------------------- C o m p u t e d ---------------------------------------------------
computed: {},
// *----------------------- L i f e   c i r c l e ---------------------------------------------
created() {
    this.$store.dispatch('base/getInit')
},
// *----------------------- M e t h o d s -----------------------------------------------------
methods: {},
// *----------------------- W a t c h ---------------------------------------------------------
watch: {}
}
</script>
