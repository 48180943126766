import Vue from 'vue';
import Router from 'vue-router'
import moment from 'moment'
import PortalVue from 'portal-vue'
import SchedulesComponent from './components/SchedulesComponent';
import SchedulesGameComponent from './components/SchedulesGameComponent';
import ScheduleLayout from './layout/ScheduleLayout';
import VueMeta from 'vue-meta'


const routes = [
  { path: '/game/:id', component: SchedulesGameComponent },
  { path: '/:season?/:team?', component: SchedulesComponent },
]

Vue.use(Router)
Vue.use(PortalVue)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.filter('formatDay', function(value) {
  if (value) {
    return moment(String(value)).format('dddd, MMMM Do, YYYY')
  }
});
Vue.filter('formatGame', function(value) {
  if (value) {
    return moment(String(value)).format('h:mm A')
  }
});

Vue.filter('checkStatValue', function (value) {
  if (!value) return 0
  return value
})

const router = new Router({
  mode: 'history',
  routes,
  base:'/schedules'
})

export default function() {
    if(!document.querySelectorAll('#schedules').length){
      return
    }
    new Vue({
        el: '#schedules',
        router,
        delimiters: [
            '${', '}'
        ],
        components: {
        },
        methods: {},
        mounted() {
        },
        render: h => h(ScheduleLayout),
        computed: {}
    });
}
