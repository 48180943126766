var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "base block" }, [
    _c("div", { staticClass: "table-container w-full" }, [
      _c(
        "div",
        {
          staticClass: "selectors grid grid-cols-10 gap-4 mb-4",
          class: { "opacity-50": _vm.loading }
        },
        [
          _c("div", { staticClass: "col-span-10 lg:col-span-1" }, [
            _c("div", { staticClass: "genericSelect" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.year,
                      expression: "filters.year"
                    }
                  ],
                  attrs: { name: "", id: "" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filters,
                          "year",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.selectFilter($event, "year")
                      }
                    ]
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("Year (all)")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.filterOptions.years, function(year, index) {
                    return _c("option", { domProps: { value: year.id } }, [
                      _vm._v(_vm._s(year.year))
                    ])
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-10 lg:col-span-2" }, [
            _c("div", { staticClass: "genericSelect" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.type,
                      expression: "filters.type"
                    }
                  ],
                  attrs: { name: "", id: "" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filters,
                          "type",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.selectFilter($event, "type")
                      }
                    ]
                  }
                },
                _vm._l(_vm.filterOptions.types, function(type, index) {
                  return _c("option", { domProps: { value: type.id } }, [
                    _vm._v(_vm._s(type.name))
                  ])
                }),
                0
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-10 lg:col-span-3" }, [
            _c("div", { staticClass: "genericSelect" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.season,
                      expression: "filters.season"
                    }
                  ],
                  attrs: { name: "", id: "" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filters,
                          "season",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.selectFilter($event, "season")
                      }
                    ]
                  }
                },
                [
                  _c("option", { domProps: { value: null } }, [
                    _vm._v("League (all)")
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.filterOptions.seasons, function(season, index) {
                    return _c("option", { domProps: { value: season.id } }, [
                      _vm._v(_vm._s(season.name))
                    ])
                  })
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-span-10 lg:col-span-2" }, [
            _c("div", { staticClass: "genericSelect" }, [
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.filters.playoffs,
                      expression: "filters.playoffs"
                    }
                  ],
                  attrs: { name: "", id: "" },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.filters,
                          "playoffs",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        return _vm.selectFilter($event, "playoffs")
                      }
                    ]
                  }
                },
                [
                  _c("option", { domProps: { value: false } }, [
                    _vm._v("Regular Season")
                  ]),
                  _vm._v(" "),
                  _c("option", { domProps: { value: true } }, [
                    _vm._v("Playoffs")
                  ])
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _vm.filterOptions.teams.length > 0
            ? _c("div", { staticClass: "col-span-10 lg:col-span-2" }, [
                _c("div", { staticClass: "genericSelect" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.filters.team,
                          expression: "filters.team"
                        }
                      ],
                      attrs: { name: "", id: "" },
                      on: {
                        change: [
                          function($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function(o) {
                                return o.selected
                              })
                              .map(function(o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              _vm.filters,
                              "team",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                          function($event) {
                            return _vm.selectFilter($event, "team")
                          }
                        ]
                      }
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("All Teams")
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.filterOptions.teams, function(team, index) {
                        return _c("option", { domProps: { value: team.id } }, [
                          _vm._v(_vm._s(team.name))
                        ])
                      })
                    ],
                    2
                  )
                ])
              ])
            : _vm._e()
        ]
      ),
      _vm._v(" "),
      _vm.loading
        ? _c("section", { staticClass: "py-12" }, [
            _c(
              "div",
              { staticClass: "loading-holder" },
              [_c("LoadingSvg", { attrs: { fill: "#2C2D70" } })],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "w-full overflow-scroll",
          class: { hidden: _vm.loading }
        },
        [
          _c("vuetable", {
            ref: "vuetable",
            attrs: {
              fields: _vm.fields,
              "api-mode": false,
              "data-manager": _vm.dataManager,
              css: _vm.style.table,
              "per-page": _vm.perPage,
              "pagination-path": "pagination"
            },
            on: { "vuetable:pagination-data": _vm.onPaginationData }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { class: { hidden: _vm.loading }, staticStyle: {} },
        [
          _c("vuetable-pagination", {
            ref: "pagination",
            attrs: { css: _vm.style.pagination },
            on: { "vuetable-pagination:change-page": _vm.onChangePage }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }