import Vue from 'vue';
import Router from 'vue-router'
import moment from 'moment'
import PortalVue from 'portal-vue'
import StatsComponent from './components/StatsComponent';
import PlayerStatsComponent from './components/PlayerStatsComponent';
import StatsLayout from './layout/StatsLayout';
import VueMeta from 'vue-meta';

const routes = [
  { path: '/player/:id', component: PlayerStatsComponent },
  { path: '/', component: StatsComponent },
]

Vue.use(Router)
Vue.use(PortalVue)
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})



const router = new Router({
  mode: 'history',
  routes,
  base:'/stats'
})

export default function() {
    if(!document.querySelectorAll('#stats').length){
      return
    }
    new Vue({
        el: '#stats',
        router,
        delimiters: [
            '${', '}'
        ],
        components: {
        },
        methods: {},
        mounted() {
        },
        render: h => h(StatsLayout),
        computed: {}
    });
}
