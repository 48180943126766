var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "135",
        height: "140",
        viewBox: "0 0 135 140",
        xmlns: "http://www.w3.org/2000/svg",
        fill: _vm.fill
      }
    },
    [
      _c("rect", { attrs: { y: "10", width: "15", height: "120", rx: "6" } }, [
        _c("animate", {
          attrs: {
            attributeName: "height",
            begin: "0.5s",
            dur: "1s",
            values: "120;110;100;90;80;70;60;50;40;140;120",
            calcMode: "linear",
            repeatCount: "indefinite"
          }
        }),
        _vm._v(" "),
        _c("animate", {
          attrs: {
            attributeName: "y",
            begin: "0.5s",
            dur: "1s",
            values: "10;15;20;25;30;35;40;45;50;0;10",
            calcMode: "linear",
            repeatCount: "indefinite"
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "rect",
        { attrs: { x: "30", y: "10", width: "15", height: "120", rx: "6" } },
        [
          _c("animate", {
            attrs: {
              attributeName: "height",
              begin: "0.25s",
              dur: "1s",
              values: "120;110;100;90;80;70;60;50;40;140;120",
              calcMode: "linear",
              repeatCount: "indefinite"
            }
          }),
          _vm._v(" "),
          _c("animate", {
            attrs: {
              attributeName: "y",
              begin: "0.25s",
              dur: "1s",
              values: "10;15;20;25;30;35;40;45;50;0;10",
              calcMode: "linear",
              repeatCount: "indefinite"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c("rect", { attrs: { x: "60", width: "15", height: "140", rx: "6" } }, [
        _c("animate", {
          attrs: {
            attributeName: "height",
            begin: "0s",
            dur: "1s",
            values: "120;110;100;90;80;70;60;50;40;140;120",
            calcMode: "linear",
            repeatCount: "indefinite"
          }
        }),
        _vm._v(" "),
        _c("animate", {
          attrs: {
            attributeName: "y",
            begin: "0s",
            dur: "1s",
            values: "10;15;20;25;30;35;40;45;50;0;10",
            calcMode: "linear",
            repeatCount: "indefinite"
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "rect",
        { attrs: { x: "90", y: "10", width: "15", height: "120", rx: "6" } },
        [
          _c("animate", {
            attrs: {
              attributeName: "height",
              begin: "0.25s",
              dur: "1s",
              values: "120;110;100;90;80;70;60;50;40;140;120",
              calcMode: "linear",
              repeatCount: "indefinite"
            }
          }),
          _vm._v(" "),
          _c("animate", {
            attrs: {
              attributeName: "y",
              begin: "0.25s",
              dur: "1s",
              values: "10;15;20;25;30;35;40;45;50;0;10",
              calcMode: "linear",
              repeatCount: "indefinite"
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "rect",
        { attrs: { x: "120", y: "10", width: "15", height: "120", rx: "6" } },
        [
          _c("animate", {
            attrs: {
              attributeName: "height",
              begin: "0.5s",
              dur: "1s",
              values: "120;110;100;90;80;70;60;50;40;140;120",
              calcMode: "linear",
              repeatCount: "indefinite"
            }
          }),
          _vm._v(" "),
          _c("animate", {
            attrs: {
              attributeName: "y",
              begin: "0.5s",
              dur: "1s",
              values: "10;15;20;25;30;35;40;45;50;0;10",
              calcMode: "linear",
              repeatCount: "indefinite"
            }
          })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }