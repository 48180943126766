var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "base block" }, [
    _c(
      "span",
      {
        staticClass:
          "text-3xl sm:text-2xl block border-b border-gray-200 pb-2 mb-2"
      },
      [_vm._v(_vm._s(_vm.team) + " " + _vm._s(_vm.score))]
    ),
    _vm._v(" "),
    _c("table", { staticClass: "table-auto w-full stat-table" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.boxData, function(statRow, index) {
            return _c("tr", { class: { "bg-gray-200": index % 2 === 0 } }, [
              _c("td", { staticClass: "px-4 py-2 text-left" }, [
                _vm._v(
                  _vm._s(statRow.player.first_name) +
                    " " +
                    _vm._s(statRow.player.last_name)
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.ab)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.r)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(
                  _vm._s(statRow.b1 + statRow.b2 + statRow.b3 + statRow.hr)
                )
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.b1)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.b2)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.b3)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.hr)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.rbi)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.bb)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.k)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.sacf)))
              ]),
              _vm._v(" "),
              _c("td", { staticClass: "px-4 py-2" }, [
                _vm._v(_vm._s(_vm._f("checkStatValue")(statRow.sacb)))
              ])
            ])
          }),
          _vm._v(" "),
          _c("tr", { staticClass: "border-t border-blue-300 font-bold" }, [
            _c("td", { staticClass: "px-4 py-2 text-left" }),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.ab)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.r)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm.totals.h))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.b1)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.b2)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.b3)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.hr)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.rbi)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.bb)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.k)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.sacf)))
            ]),
            _vm._v(" "),
            _c("td", { staticClass: "px-4 py-2" }, [
              _vm._v(_vm._s(_vm._f("checkStatValue")(_vm.totals.sacb)))
            ])
          ])
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", { staticClass: "px-4 py-2 text-left" }, [_vm._v("Player")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("AB")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("R")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("H")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("1B")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("2B")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("3B")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("HR")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("RBI")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("BB")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("K")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("SACF")]),
        _vm._v(" "),
        _c("th", { staticClass: "px-4 py-2" }, [_vm._v("SACB")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }