var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "base block" },
    _vm._l(_vm.fields, function(field, index) {
      return _c("div", { staticClass: "field-block mb-6" }, [
        _c("span", { staticClass: "text-2xl block" }, [
          _vm._v(_vm._s(field.name))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "block" }, [_vm._v(_vm._s(field.address))]),
        _vm._v(" "),
        _c("span", { staticClass: "block" }, [
          _vm._v(_vm._s(field.city) + ", "),
          _c("span", { staticClass: "uppercase" }, [
            _vm._v(_vm._s(field.state))
          ]),
          _vm._v(" " + _vm._s(field.zip))
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "directions mt-2 block" }, [
          _c(
            "a",
            {
              staticClass: "text-bold text-blue-500 underline",
              attrs: { href: field.urlName, target: "_blank" }
            },
            [_vm._v("Directions")]
          )
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }