<template>
<section class="base block">
  <div class="table-container w-full">
    <div class="selectors grid grid-cols-10 gap-4 mb-4" v-bind:class="{ 'opacity-50': loading }">
      <div class="col-span-10 lg:col-span-1">
        <div class="genericSelect">
          <select name="" id="" @change="selectFilter($event, 'year')" v-model="filters.year">
            <option :value="null">Year (all)</option>
            <option :value="year.id" v-for="(year, index) in filterOptions.years">{{year.year}}</option>
          </select>
        </div>
      </div>
      <div class="col-span-10 lg:col-span-2">
        <div class="genericSelect">
          <select name="" id="" @change="selectFilter($event, 'type')" v-model="filters.type">
            <!-- <option :value="null">Type</option> -->
            <option :value="type.id" v-for="(type, index) in filterOptions.types">{{type.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-span-10 lg:col-span-3">
        <div class="genericSelect">
          <select name="" id="" @change="selectFilter($event, 'season')" v-model="filters.season">
            <option :value="null">League (all)</option>
            <option :value="season.id" v-for="(season, index) in filterOptions.seasons">{{season.name}}</option>
          </select>
        </div>
      </div>
      <div class="col-span-10 lg:col-span-2">
        <div class="genericSelect">
          <select name="" id="" @change="selectFilter($event, 'playoffs')" v-model="filters.playoffs">
            <option :value="false">Regular Season</option>
            <option :value="true">Playoffs</option>
          </select>
        </div>
      </div>
      <div class="col-span-10 lg:col-span-2" v-if="filterOptions.teams.length > 0">
        <div class="genericSelect">
          <select name="" id="" @change="selectFilter($event, 'team')" v-model="filters.team">
            <option :value="null">All Teams</option>
            <option :value="team.id" v-for="(team, index) in filterOptions.teams">{{team.name}}</option>
          </select>
        </div>
      </div>
    </div>
    <section v-if="loading" class="py-12">
      <div class="loading-holder">
        <LoadingSvg fill="#2C2D70"></LoadingSvg>
      </div>
    </section>
    <div class="w-full overflow-scroll" v-bind:class="{ hidden: loading }">
      <vuetable ref="vuetable"
        :fields="fields"
        :api-mode="false"
        :data-manager="dataManager"
        :css="style.table"
        :per-page="perPage"
        pagination-path="pagination"
  	    @vuetable:pagination-data="onPaginationData"
      ></vuetable>
    </div>
    <div style="mt-8 w-full" v-bind:class="{ hidden: loading }">
      <vuetable-pagination ref="pagination" :css="style.pagination"
        @vuetable-pagination:change-page="onChangePage"
      ></vuetable-pagination>
    </div>
  </div>

</section>

</template>

<script>

import axios from '../config/axios'
import { map, tail, times, uniq } from 'lodash';
import moment from 'moment';
import LoadingSvg from './LoadingSvg';
import Vuetable from 'vuetable-2';
import tableStyle from './tableStyle';
import VuetablePagination from "vuetable-2/src/components/VuetablePagination";

export default {
name: 'StatsComponent',
// *----------------------- P r o p s ----------------------------------------------------------
props: {},
// *----------------------- D a t a -----------------------------------------------------------
components:{
  Vuetable,
  VuetablePagination,
  LoadingSvg
},
watch: {
    // data(newVal, oldVal) {
    //   this.$refs.vuetable.refresh();
    // }
  },
data() {
  return {
    style: tableStyle,
    data:[],
    perPage:50,
    filterOptions:{
      years:null,
      types:[{
        id:6,
        name:"Men's"
      }],
      seasons:null,
      teams:[]
    },
    loading:false,
    filters:{
      year:null,
      type:6,
      season:null,
      team:null,
      playoffs:false
    },
    sortOrder: [
      {
        field: 'runc_g',
        direction: 'desc'
      }
    ],
    fields:[
      {
        title: 'First Name',
        name: 'first_name',
        sortField: 'first_name'
      },
      {
        title: 'Last Name',
        name: 'last_name',
        sortField: 'last_name'
      },
      {
        title: 'G',
        name: 'stats.games',
        sortField: 'stas.games'
      },
      {
        title: 'AB',
        name: 'stats.ab',
        sortField: 'stats.ab'
      },
      {
        title: 'R',
        name: 'stats.r',
        sortField: 'stats.r'
      },
      {
        title: 'H',
        name: 'stats.h',
        sortField: 'stats.h'
      },
      {
        title: '2B',
        name: 'stats.b2',
        sortField: 'stats.b2',
      },
      {
        title: '3B',
        name: 'stats.b3',
        sortField: 'stats.b3',
        direction: "desc"
      },
      {
        title: 'HR',
        name: 'stats.hr',
        sortField: 'stats.hr'
      },
      {
        title: 'RBI',
        name: 'stats.rbi',
        sortField: 'stats.rbi'
      },
      {
        title: ' BB',
        name: 'stats.bb',
        sortField: 'stats.bb'
      },
      {
        title: 'SO',
        name: 'stats.k',
        sortField: 'stats.k'
      },
      {
        title: 'AVG',
        name: 'stats.avg',
        sortField: 'stats.avg'
      },
      {
        title: 'OBP',
        name: 'stats.obp',
        sortField: 'stats.obp'
      },
      {
        title: 'SLG',
        name: 'stats.slg',
        sortField: 'stats.slg'
      },
      {
        title: 'OPS',
        name: 'stats.ops',
        sortField: 'stats.ops'
      },
      {
        title: 'RUNC/G',
        name: 'stats.runc_g',
        sortField: 'stats.runc_g'
      },

    ],
  }
},
mounted(){
  this.loading = true;
  this.getStats(false, ()=>{
    this.getFilters(()=>{
      this.loading = false;
    })
  })
},
// *----------------------- C o m p u t e d ---------------------------------------------------
computed: {},
// *----------------------- L i f e   c i r c l e ---------------------------------------------
created() {

},
// *----------------------- M e t h o d s -----------------------------------------------------
methods: {
  getStats(withPush, cb){
    let {year, type, season, team, playoffs} = this.filters;
    let statsUrl =  `/stats?year=${year}&type=${type}&season=${season}&team=${team}&playoffs=${playoffs}`;
    axios.get(statsUrl)
      .then(response => {
        this.data = response.data
        this.$refs.vuetable.refresh();
        // this.$refs.vuetable.setData(this.data);
        // this.filteredGames = response.data
        if(cb){
          cb();
        }
      })
  },
  getFilters(cb){
    let {year, type, season, team} = this.filters;
    let statsUrl =  `/stats/filters?year=${year}&type=${type}&season=${season}&team=${team}`;
    axios.get(statsUrl)
      .then(response => {
        this.filterOptions = response.data
        console.log("FILTERS", this.filters)
        if(cb){
          cb();
        }
      })
  },
  onPaginationData (paginationData) {
    this.$refs.pagination.setPaginationData(paginationData)
  },
  // when the user click something that causes the page to change,
  // call "changePage" method in Vuetable, so that that page will be
  // requested from the API endpoint.
  onChangePage (page) {
    this.$refs.vuetable.changePage(page)
  },
  dataManager(sortOrder, pagination) {
      if (this.data.length < 1) {
        return {
          pagination: null,
          data: []
        };
      };

      let local = this.data;

      // sortOrder can be empty, so we have to check for that as well
      if (sortOrder.length > 0) {
        console.log("SORT ORDER", sortOrder[0])
        console.log("orderBy:", sortOrder[0].sortField, sortOrder[0].direction);
        local = _.orderBy(
          local,
          sortOrder[0].sortField,
          sortOrder[0].direction
        );
      }

      pagination = this.$refs.vuetable.makePagination(
        local.length,
        this.perPage
      );
      console.log('pagination:', pagination)
      let from = pagination.from - 1;
      let to = from + this.perPage;

      return {
        pagination: pagination,
        data: _.slice(local, from, to)
      };
    },
  selectFilter(e, type){
    this.loading = true;
    if(type == 'year' || type == 'type'){
      console.log('resetting')
      this.filters.season = null;
      this.filters.team =null;
    }else if(type=="season"){
      this.filters.team = null;
    }
    this.getStats(false, ()=>{
      this.getFilters(()=>{
        this.loading = false;
      })
    })
  }
},
// *----------------------- W a t c h ---------------------------------------------------------
watch: {}
}
</script>
