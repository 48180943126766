

<template>
  <div class="loading">
    <div class="loading-container">
      <LoadingSvg :fill="fill"></LoadingSvg>
    </div>
  </div>
</template>

<script>
import LoadingSvg from './LoadingSvg'
export default {
  name: 'Loading',
  // *----------------------- P r o p s ----------------------------------------------------------
  props: ['fill'],
  // *----------------------- D a t a -----------------------------------------------------------
  data() {
    return {}
  },
  components:{
    LoadingSvg
  },
  // *----------------------- C o m p u t e d ---------------------------------------------------
  computed: {},
  // *----------------------- L i f e   c i r c l e ---------------------------------------------
  created() {},
  // *----------------------- M e t h o d s -----------------------------------------------------
  methods: {},
  // *----------------------- W a t c h ---------------------------------------------------------
  watch: {}
}
</script>
